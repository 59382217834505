import React, { useState } from "react";
import "../styles/VideoPlayer.css";
import ReactPlayer from "react-player";

const Video2Player = () => {
  const [playing, setPlaying] = useState(false); // State to control playback

  const posterUrl =
    "https://res.cloudinary.com/dabxnoxsx/image/upload/v1710239860/video-sing_yzrcqg.png";
  const videoUrl =
    "https://res.cloudinary.com/dabxnoxsx/video/upload/v1708419830/Partner_Registration_q0k1fc.mp4";

  const handleClick = (event) => {
    event.preventDefault(); // Prevent default scrolling behavior
    setPlaying(true); // Start playback on click
  };

  return (
    <div className="video-player" onClick={handleClick}>
      <ReactPlayer
        url={videoUrl}
        controls={true}
        width="100%"
        height="800px"
        light={posterUrl}
        playing={playing}
      />
    </div>
  );
};

export default Video2Player;
