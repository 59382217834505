import React, { useEffect, useState } from "react";
import "../styles/BlogSlider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function BlogSlider() {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://api.traveling-partner.com/api/blogs")
      .then((response) => {
        setBlogs(response.data.Blogs);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {blogs.map((blog) => (
          <div className="blog-box1" key={blog.id}>
            <div className="slider-box-img">
              <img src={blog.cover_image} alt="" />
            </div>
            <div className="slider-blog-text">
              <h1>{blog.main_title}</h1>
              <p className="truncated-text">{blog.description1}</p>
              <Link to={`/blog/${blog.id}`} className="slider-blog-readmore">
                Read More
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
