import { IoCloudUploadOutline } from "react-icons/io5";
import axios from "axios";

const ContactUsForm = () => {
  const submitHandler = (event) => {
    event.preventDefault();
    const name =
      event.target.firstName.value + " " + event.target.lastName.value;
    const subject = "abc";
    //  event.target.subject.value;
    const email = event.target.email.value;
    // const photo = event.target.photo.value;
    const message = event.target.message.value;
    axios
      .post("https://api.traveling-partner.com/api/contact", {
        name,
        email,
        message,
        subject,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="form-main-div">
      <form onSubmit={submitHandler}>
        <div className="first-name-last">
          <input type="text" name="firstName" placeholder="First Name" />
          <input type="text" name="lastName" placeholder="Last Name" />
        </div>
        <div className="first-name-last">
          <input type="email" name="email" placeholder="Email" required />
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            inputMode="numeric"
            pattern="[0-9]*"
            required
          />
        </div>
        <div className="text-msg">
          <textarea name="message" placeholder=" Message" />
        </div>
        <div className="contac-us-btn-img">
          <div className="for-upload-img">
            <label htmlFor="photo"></label>
            <button onClick={() => document.getElementById("photo").click()}>
              <IoCloudUploadOutline />
              <p style={{ padding: "0px 5px" }}>Upload</p>
            </button>
            <input
              className="Add-blog-img"
              type="file"
              id="photo"
              name="photo"
              accept="image/*"
              style={{ display: "none" }} // Add this line
            />
          </div>
          <div className="contact-us-btn">
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
