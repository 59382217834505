import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../styles/Blog.css";
import Footer from "../Components/Footer";
import BlogSliderForBlog from "../Components/BlogSliderForBlog";

function Blog() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`https://api.traveling-partner.com/api/blogs/${id}`)
      .then((response) => {
        setBlog(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);
  console.log("Blog data:", blog);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="main-blog-main">
      <div className="blog-page2">
        <div className="blog-page">
          <h1 className="blog-title-main">{blog.Blogs.main_title}</h1>
          <div className="for-cover-img">
            <img
              src={blog.Blogs.cover_image}
              alt={blog.Blogs.main_title}
              className="blog-image"
            />
          </div>
          <div className="blog-container">
            <div className="title1-main">
              <div className="tittle1-img">
                <img
                  src={blog.Blogs.image1}
                  alt={blog.Blogs.main_title}
                  className="blog-image"
                />
              </div>

              <div className="tittle1-text">
                <h1 className="blog-title">{blog.Blogs.title1}</h1>
                <p className="blog-content-text">{blog.Blogs.description1}</p>
              </div>
            </div>

            <div className="title1-main1">
              <div className="tittle1-img">
                <img
                  src={blog.Blogs.image2}
                  alt={blog.Blogs.title1}
                  className="blog-image"
                />
              </div>

              <div className="tittle1-text">
                <h1 className="blog-title">{blog.Blogs.title2}</h1>
                <p className="blog-content-text">{blog.Blogs.description2}</p>
              </div>
            </div>
            <div className="title1-main">
              <div className="tittle1-img">
                <img
                  src={blog.Blogs.image3}
                  alt={blog.Blogs.title3}
                  className="blog-image"
                />
              </div>

              <div className="tittle1-text">
                <h1 className="blog-title">{blog.Blogs.title3}</h1>
                <p className="blog-content-text">{blog.Blogs.description3}</p>
              </div>
            </div>
            <div className="for-owner">
              <p>Created by Traveling Partner</p>
            </div>
          </div>
        </div>
      </div>
      <div className="for-blog-slider">
        <div className="main-text-bloger">
          <h1>Explore Our Latest Blogs</h1>
        </div>

        <BlogSliderForBlog />
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
