import React, { useState } from "react";
import "../styles/Home.css";
import circley from "../Assist/img/circle-img.png";
import Gplaystore from "../Assist/img/google.png";
import Iplaystore from "../Assist/img/iso.png";
import Register from "../Assist/Taxi-stand-img/home-page-b-img.png";
import helpingHand from "../Assist/img/Register As A Driver.png";
import Partner from "../Assist/img/Register As A Partner.png";
import Featured1 from "../Assist/img/Categories (1).png";
import Featured2 from "../Assist/img/Categories (2).png";
import Featured3 from "../Assist/img/Categories (3).png";
import Featured4 from "../Assist/img/Categories (4).png";
import Featured5 from "../Assist/img/Categories (5).png";
import Featured6 from "../Assist/img/Categories (6).png";
import Aboutimg from "../Assist/img/About-us.png";
// import blog from "../Assist/img/blog-home.png"
import blog1 from "../Assist/img/blog-home1.png";
import blog2 from "../Assist/img/blog-img2.png";
import safe1 from "../Assist/img/safe1.png";
import safe2 from "../Assist/img/Female Driver.png";
import Mape from "../Assist/img/Mape.png";
import poster from "../Assist/img/video.png";
import { Link } from "react-router-dom";
import { RiContactsFill } from "react-icons/ri";
import { FaRegHandshake } from "react-icons/fa6";
import Footer from "./Footer";
import CarAnimation from "./CarAnimation";
import VideoPlayer from "./VideoPlayer";
import Video2Player from "./Video2Player";
import BlogSlider from "./BlogSlider";
import ContactUsForm from "./ContactUsForm";

function Home() {
  return (
    <div>
      <div className="circledev-main ">
        <div className="circle-text">
          <div className="car-animation">
            <CarAnimation />
          </div>
          <p>
            GET TO YOUR <span>DESTINATION</span>{" "}
          </p>
          <h2>safe & secured</h2>
          <h3>
            With <span>TRAVELPARTNER</span>
          </h3>

          <div className="playstore-img">
            <div className="playstore-img-inner">
              <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                {" "}
                <img src={Gplaystore} alt="Google-PlayStore" />
                <br />
              </Link>
            </div>
            <div className="playstore-img-inner">
              <Link to="https://www.apple.com/app-store/">
                <img src={Iplaystore} alt="App-Store" />
              </Link>
            </div>
          </div>
        </div>

        <div className="circle-img-inner">
          <img src={circley} alt="" />
        </div>
      </div>
      {/* Register As A Driver section */}

      <div className="Register-As-Driver-main">
        <div className="Register-As-img">
          <img src={Register} alt="Mobile-img" />
        </div>
        <div className="Register-As-btn">
          <div className="Register-As-A-Driver-btn ">
            <button>
              <RiContactsFill />
              <p>Register As A Driver</p>
            </button>
          </div>
          <div className="Register-As-A-Driver-btn ">
            <button>
              <FaRegHandshake />
              <p>Register As A Partner </p>
            </button>
          </div>
        </div>
      </div>
      {/* Register As A Driver section  for responsive */}
      {/* <div className="Register-As-Driver-main">
                <div className="Register-As-img">
                    <img src={Register} alt="Mobile-img" />
                </div>
                <div className="Register-As-btn">
                    <Link>  <img src={helpingHand} alt="help" /></Link>
                    <Link>  <img src={Partner} alt="help" style={{ width: "53%" }} /></Link>
                </div>
            </div> */}

      {/* Featured Categories */}
      <div className="Featured-Categories">
        <div className="main-featured-text">
          <h1>Featured Categories</h1>
        </div>

        <div className="box-main">
          <div className="box11 box111 ">
            <div className="card">
              <img src={Featured6} alt="" />
              <div className="for-feat-text">
                <span>Taxi stand</span>
                <p>
                  Traveling Partner links you directly to multiple taxi stands
                  effortlessly. Experience commission-free rides and negotiate
                  fares at your convenience for smooth, cost-effective commuting
                  without extra fees.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={Featured3} alt="" />
              <div className="for-feat-text">
                <span>Logistic</span>
                <p>
                  Our innovative logistics service lets you connect directly
                  with transport providers, optimize routes, and share the costs
                  – because smart logistics make happy journeys. Ditch the
                  commission fees, not your efficiency!
                </p>
              </div>
            </div>
          </div>
          <div className="box22 box222">
            <div className="card">
              <img src={Featured5} alt="" />
              <div className="for-feat-text">
                <span>Pool Ride</span>
                <p>
                  Enjoy the joys of shared rides without the burden of extra
                  costs. Traveling Partner connects you with fellow travelers
                  heading your way and If you not found any plan your own
                  one.Save money and enjoy the ride at no additional commission
                  fees.{" "}
                </p>
              </div>
            </div>

            <div className="card">
              <img src={Featured1} alt="" />
              <div className="for-feat-text">
                <span>Tracking</span>
                <p>
                  Keep tabs on your rides, deliveries, and logistics in
                  real-time with Traveling Partner's commission-free tracking
                  feature.Tracking operates based on mutual user agreement,
                  ensuring privacy and a hassle-free experience.
                </p>
              </div>
            </div>
          </div>
          <div className="box33">
            <div className="card">
              <img src={Featured4} alt="" />
              <div className="for-feat-text">
                <span>Delivery</span>
                <p>
                  Connecting users directly with reliable delivery services for
                  efficient item transfers at a fraction of the cost. Birthday
                  cake or business documents, we've got you covered,
                  commission-free!
                </p>
              </div>
            </div>
            <div className="card">
              <img src={Featured2} alt="" />
              <div className="for-feat-text">
                <span>Trip</span>
                <p>
                  Family outing or epic road trip? Traveling Partner has your
                  back. Connect with drivers, customize your travel plan, and
                  enjoy hassle-free trip planning. No commission blues, just
                  pure travel joy!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* video section */}
      <div className="video-main2" style={{ width: "100%" }}>
        <div className="video1-text">
          <h1>how to register as a driver</h1>
        </div>
        <VideoPlayer height={400} />
      </div>
      {/* About-us */}
      <div className="About-us-main">
        <div className="about-us-text">
          <h1>About us</h1>
          <div className="about-us-img12">
            <img src={Aboutimg} alt="" />
          </div>
          <p>
            At Traveling Partner, our purpose is to revolutionize the landscape
            of mobility, creating a space where users can effortlessly connect
            and collaborate. By fostering a community-centric environment, our
            platform eliminates the financial burdens of additional fees,
            providing a dynamic hub for individuals to share rides, make
            deliveries, and plan trips collaboratively. <br />
            <br />
            Through transparency, user empowerment, and a commitment to a
            commission-free approach, we aim to redefine the very essence of
            travel and connectivity in Pakistan. Traveling Partner is not just
            an app; it's a movement towards a more connected, collaborative, and
            commission-free future for everyone.
          </p>
          <div className="read-about">
            {" "}
            <Link to="/AboutUs">Learn more</Link>
          </div>
        </div>
        <div className="about-us-img1">
          <img src={Aboutimg} alt="" />
        </div>
      </div>

      <div className="video-main1">
        <div className="video1-text">
          <h1>How to register as a Partner</h1>
        </div>
        <Video2Player />
      </div>
      {/* Our Blog And News */}
      <div className="blog-main">
        <div className="main-text">
          <h1>Our Blog And News</h1>
          <p>
            Explore travel tales, tips, and updates from our community. Get
            inspired and join the journey today!
            <br /> From breathtaking landscapes to hidden gems, let's make
            memories together.
          </p>
        </div>

        <div className="blogslider-main">
          <BlogSlider />
        </div>
      </div>

      {/* Safety & Security Services */}
      <div className="Security-Services-main-wrapper">
        <h1>Safety & Security Services</h1>
        <p>
          At Traveling Partner, we prioritize your safety, providing essential
          security features to enhance your peace of mind throughout your
          journey.
          <br /> enhance your peace of mind throughout your journey.
        </p>
        <div className="Security-Services-main">
          <div className="safe-main-text">
            <h1>Safe and Secure</h1>
            <div className="safe-main-img2nd">
              <img src={safe1} alt="" />
            </div>
            <p>
              Experience a journey in a safe and secure environment with
              Traveling Partner. Our commitment to your well-being is reflected
              in our comprehensive safety measures. From real-time tracking to
              transparent communications, we ensure that your safety is at the
              forefront of every ride, delivery, trip or logistic service.
            </p>
            <div className="safe-btn-y">
              <Link to="/Blog">About Us</Link>
            </div>
          </div>
          <div className="safe-main-img">
            <img src={safe1} alt="" />
          </div>
          <div className="safe-btn-y1">
            <Link to="/AboutUS">About Us</Link>
          </div>
        </div>
        <div className="Security-Services-main1">
          <div className="safe-main-img">
            <img src={safe2} alt="" />
          </div>

          <div className="safe-main-text ">
            <h1>Female Driver </h1>
            <div className="safe-main-img2nd">
              <img src={safe1} alt="" />
            </div>
            <p>
              At Traveling Partner, we prioritize your security, offering a
              unique feature that adds an extra layer of protection, our
              Female-Only Option. This security-focused feature is designed for
              both female drivers and passengers, providing a reassuring
              environment for everyone. For female drivers, the Female-Only
              Option allows them to choose rides exclusively with female
              passengers, enhancing their comfort and confidence during each
              journey. Similarly, female passengers can opt for pooling with
              female partners and rides driven by female drivers, ensuring a
              secure and tailored travel experience.
            </p>
            <div className="safe-btn-y">
              <Link to="/AboutUS">About Us</Link>
            </div>
          </div>
          <div className="safe-btn-y1">
            <Link to="/Blog">About Us</Link>
          </div>
        </div>
      </div>

      {/* Contact us============ */}
      <div className="contactus-main">
        <div className="contact-text">
          <h1>Contact us</h1>
        </div>

        <div className="contect-us-form">
          <ContactUsForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
