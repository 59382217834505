import { Link } from "react-router-dom"
import maimImg from "../Assist/Taxi-stand-img/AboutUS.png"
import Gplaystore from "../Assist/img/google.png"
import Iplaystore from "../Assist/img/iso.png"
import p1 from "../Assist/Taxi-stand-img/Purpose Of Travel Partner.png"
import p2 from "../Assist/Taxi-stand-img/Feature Of The App.png"
import Aim from "../Assist/Taxi-stand-img/Aim.png"
import ChooseUS1 from "../Assist/Taxi-stand-img/Choose Us.png"
import ChooseUS2 from "../Assist/Taxi-stand-img/Choose Us (2).png"
import ChooseUS3 from "../Assist/Taxi-stand-img/Choose Us (3).png"
import "../styles/AboutUs.css"
import Footer from "./Footer"

function AboutUs() {
    return (
        <div>
            <div className="About-cover">
                <div className="About-text">
                    <div className="About-h3">
                    <h3> <span>About us</span></h3>
                    </div>
                    <h4 style={{ fontSize: "20px", fontWeight: "500", marginTop: "10px" }}>Redefining Connections, Empowering Journeys in Pakistan</h4>
                    <p>Welcome to Traveling Partner, your revolutionary platform reshaping connections and
                        mobility across the vibrant landscape of Pakistan. Dive into a transformative experience
                        where seamless transport, efficient deliveries, reliable logistics, and collaborative trip
                        planning converge under the banner of a commission-free ecosystem. </p>

                    <div className="About-playstore-img">
                        <div className="About-playstore-img-inner">
                            <Link to="https://play.google.com/store/apps?hl=en&gl=US">  <img src={Gplaystore} alt="Google-PlayStore" /><br /></Link>
                        </div>
                        <div className="About-playstore-img-inner">
                            <Link to="https://www.apple.com/app-store/"><img src={Iplaystore} alt="App-Store" /></Link>
                        </div>
                    </div>
                </div>
                <div className="About-img-inner">
                    <img src={maimImg} alt="" />
                </div>
            </div>

            {/* Purpose Of Travel Partner  */}
            <div className="Purpose-travling-cover">
                <div className="Purpose-Travel-box1 ">
                    <div className="for-p-text">
                        <h2>Purpose Of Travel Partner </h2>
                        <p>At Traveling Partner, our purpose is to revolutionize the landscape of mobility, creating a
                            space where users can effortlessly connect and collaborate. By fostering a
                            community-centric environment, our platform eliminates the financial burdens of
                            additional fees, providing a dynamic hub for individuals to share rides, make deliveries,
                            and plan trips collaboratively. Through transparency, user empowerment, and a
                            commitment to a commission-free approach, we aim to redefine the very essence of
                            travel and connectivity in Pakistan. Traveling Partner is not just an app; it's a movement
                            towards a more connected, collaborative, and commission-free future for everyone.</p>
                    </div>
                    <div className="for-p-img">
                        <img src={p1} alt="" />
                    </div>
                    <div className="for-p-img1">
                        <img src={p1} alt="" />
                    </div>
                </div>
                <div className="for-p-img1">
                    <img src={p2} alt="" />
                </div>
                <div className="Purpose-Travel-box1">
                    <div className="for-p-img">
                        <img src={p2} alt="" />
                    </div>
                    <div className="for-p-text">
                        <h2>Feature Of The App</h2>
                        <FeatureOfThe />
                    </div>
                </div>
                <div className="Aim-Partner-main">
                    <div className="Aim-smal-div">
                        <h2>Aim Of Travel Partner</h2>
                        <p>At Traveling Partner, we aim to redefine how people connect, collaborate, and move
                            within Pakistan by providing, </p>
                        <h3>Commission-Free Environment <br />   Transform the Transportation Landscape<br /> Facilitate Collaboration and Connectivity <br />  User-Driven Flexibility </h3>
                    </div>
                    <div className="Aim-img">
                        <img src={Aim} alt="" />
                    </div>
                </div>
            </div>

            {/* .Reasons Why Choose Us  */}
            <div className="Choose-Us-main">
                <div className="Choose-Us-main-text">
                    <h1>Reasons Why Choose Us </h1>
                </div>
                <div className="Choose-Us-box">
                    <div className="Choose-Us-box1">
                        <div className="Choose-Us-card">
                            <img src={ChooseUS1} alt="" />
                            <h3>Community-Centric Approach</h3>
                            <p>Join a community of collaborative users. Traveling
                                Partner isn't just an app; it's a platform that connects individuals for various services,
                                creating a community-centric environment where everyone can contribute and benefit. </p>
                        </div>
                    </div>
                    <div className="Choose-Us-box2">
                        <div className="Choose-Us-card">
                            <img src={ChooseUS2} alt="" />
                            <h3>Commission-Free Transactions</h3>
                            <p>Enjoy the freedom of cost-effective transactions.
                                Traveling Partner operates on a commission-free model, allowing users to save more
                                while connecting for rides, deliveries, and logistics </p>
                        </div>
                    </div>
                    <div className="Choose-Us-box3">
                        <div className="Choose-Us-card">
                            <img src={ChooseUS3} alt="" />
                            <h3>User Empowerment </h3>
                            <p>We believe in empowering users. With Traveling Partner, you
                                have the autonomy to negotiate and decide on fares, fostering a flexible and
                                personalized experience tailored to your preferences </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default AboutUs




const FeatureOfThe = () => {
    const content = [
        "Empowering Connections: Join a community where passengers, drivers, couriers, and travelers collaborate without extra fees.",

        "Revolutionizing Mobility: Experience a commission-free environment for taxis, shared rides, deliveries, logistics, and trip planning.",

        "Community-Driven Collaboration: Be part of a platform connecting users with taxi stands, ride pools, couriers, logistics services, and trip planners, all in a community - centric, commission - free setting.",

        "Transparent & Empowering: Benefit from transparent transactions, allowing users to negotiate and manage their dealings independently for a personalized and flexible experience.",

        "Revamp Your Mobility Experience: Explore a fresh, commission-free approach to mobility, prioritizing user - driven interactions, convenience, collaboration, and empowerment.",

        "Accessible Nationwide: This app is accessible anywhere in Pakistan where internet services are available."
    ];

    return (
        <div className="shipping-container">
            <div className="shipping-content">
                {content.map((line, index) => {
                    const [beforeColon, afterColon] = line.split(':');
                    return (
                        <p key={index}>
                            <strong>{beforeColon}</strong>: {afterColon}
                        </p>
                    );
                })}
            </div>
        </div>
    );
};