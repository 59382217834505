import React, { useState } from "react";
import "../styles/VideoPlayer.css";
import ReactPlayer from "react-player";

const VideoPlayer = () => {
  const [playing, setPlaying] = useState(false); // State to control playback

  const posterUrl =
    "https://res.cloudinary.com/dabxnoxsx/image/upload/v1710239883/How_to_register_as_a_Driver_a01zuj.png";
  const videoUrl =
    "https://res.cloudinary.com/dabxnoxsx/video/upload/v1708419720/Driver_Registration_fy4in8.mp4";

  const handleClick = (event) => {
    event.preventDefault(); // Prevent default scrolling behavior
    setPlaying(true); // Start playback on click
  };

  return (
    <div className="video-player" onClick={handleClick}>
      <ReactPlayer
        url={videoUrl}
        controls={true}
        width="100%"
        height="800px"
        light={posterUrl}
        playing={playing} // Controlled playback
      />
    </div>
  );
};

export default VideoPlayer;
