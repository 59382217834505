import "../styles/signUp.css"
function SignUp() {
    return (
        <div>
            <h1>sign Up</h1>
        </div>
    )
}

export default SignUp
