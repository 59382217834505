import "../styles/Footer.css";
import Footer1 from "../Assist/logo/Footer-logo.png";
import googlep from "../Assist/img/google.png";
import appstore from "../Assist/img/iso.png";
import Email from "../Assist/img/Email.png";
import phone from "../Assist/img/Ringer Volume (1).png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="footer-wrapper">
        <div className="Footer-main">
          <div className="for-footer-logo">
            <img src={Footer1} alt="" />
          </div>
          <div className="footer-info">
            <h1>Services</h1>
            <Link to="/TaxiStand">Taxi Stand</Link>
            <Link to="/PoolRide">Pool Ride</Link>
            <Link to="/Delivery">Delivery</Link>
            <Link to="/Logistic">Logistic</Link>
          </div>
          <div className="footer-info">
            <h1>Travel parnter</h1>
            <Link to="/AboutUs">About Us</Link>
          </div>
          <div className="footer-info">
            <h1>Contact</h1>
            <div className="phone-nbr">
              {/* <img src={phone} alt="" /> */}
              {/* <p>021-12347</p> */}
            </div>
            <div className="phone-nbr">
              <img src={Email} alt="" />
              <p>info@traveling-partner.com</p>
            </div>
          </div>
          <div className="footer-info-img">
            <Link to="https://play.google.com/store/apps?hl=en&gl=US">
              <img src={googlep} alt="" />
            </Link>
            <Link to="https://www.apple.com/app-store/">
              <img src={appstore} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="blac-border-b">
        <p>Copyright © 2023 Travelpartner All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
