import { Link } from "react-router-dom";
import circley from "../Assist/Taxi-stand-img/Logistics-main.png";
import Gplaystore from "../Assist/img/google.png";
import Iplaystore from "../Assist/img/iso.png";
// import Line from "../Assist/Taxi-stand-img/Line 26.png"
import image54 from "../Assist/Taxi-stand-img/image 54.png";
import image55 from "../Assist/Taxi-stand-img/image 55.png";
import simple from "../Assist/Taxi-stand-img/Booking.png";
import shipments from "../Assist/Taxi-stand-img/Map Marker.png";
import pickup from "../Assist/img/Categories (4).png";
import delivery from "../Assist/Taxi-stand-img/Local Delivery Time.png";
import savetime from "../Assist/Taxi-stand-img/Euro Money.png";
import "../styles/Logistic.css";
import Footer from "./Footer";

function Logistic() {
  return (
    <div>
      <div className="taxi-stand-cover ">
        <div className="texi-text">
          <h3>
            {" "}
            <span>Logistics</span>
          </h3>
          <h4
            style={{ fontSize: "20px", fontWeight: "500", paddingTop: "10px" }}
          >
            {" "}
            Direct Logistics Without Added Charges
          </h4>
          <p>
            The Logistics service empowers users by enabling direct connections
            to logistic solutions without additional fees. Traveling Partner
            prioritizes a commission-free approach, allowing users to manage
            their transportation needs efficiently. Users can also share their
            vehicles, enhancing flexibility and reducing costs. It puts the
            power of negotiation and decision-making in your hands, providing a
            seamless logistics solution without added financial burdens.
          </p>
          <div className="playstore-img">
            <div className="playstore-img-inner">
              <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                {" "}
                <img src={Gplaystore} alt="Google-PlayStore" />
                <br />
              </Link>
            </div>
            <div className="playstore-img-inner">
              <Link to="https://www.apple.com/app-store/">
                <img src={Iplaystore} alt="App-Store" />
              </Link>
            </div>
          </div>
        </div>

        <div className="taxi-img-inner">
          <img src={circley} alt="" />
        </div>
      </div>

      <div className="Benefits-Drivers-main">
        <div className="Benefits-top-text">
          {/* <h1>Benefits Of Pool Ride For Drivers</h1> */}
        </div>
        <div className="Benefits-Drivers-img11">
          <img src={image54} alt="" />
        </div>

        <div className="Benefits-Drivers">
          <div className="Drive-Travelpartner-text1">
            <div className="faster-text">
              <h3 style={{ fontSize: "35px", fontWeight: "600" }}>
                Logistics benefits
              </h3>
            </div>
            <LogisticsBenefits />
          </div>
          <div className="Benefits-Drivers-img">
            <img src={image54} alt="" style={{ marginTop: "85px" }} />
          </div>
        </div>

        <div className="Benefits-Drivers">
          <div className="Benefits-Drivers-img">
            <img src={image55} alt="" />
          </div>
          <div className="Benefits-Drivers-img11">
            <img src={image55} alt="" />
          </div>
          <div className="Drive-Travelpartner-text1">
            <div className="faster-text">
              <h3 style={{ fontSize: "30px", fontWeight: "600" }}>
                faster than you think Safer than you expect!
              </h3>
            </div>
            <div className="for-arrow1">
              {/* <div className="line-img1"><img src={Line} alt="" /></div> */}
              <p>
                our courier, packing and moving services support your goods or
                documents reach at different locations worldwide. Reliable and
                safe transportation of products allow for online shopping and
                delivery to customers doors
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Featured-Categories">
        <div className="main-featured-text">
          <h1>our services</h1>
        </div>

        <div className="box-main" style={{ gap: "40px" }}>
          <div className="box11">
            <div className="card">
              <img src={simple} alt="" />
              <div className="for-feat-text">
                <span>simple booking</span>
                <p>
                  Empower yourself with easy and user-friendly booking
                  procedures.{" "}
                </p>
              </div>
            </div>
            <div className="card" style={{ marginTop: "30px" }}>
              <img src={pickup} alt="" />
              <div className="for-feat-text">
                <span>on time pickup</span>
                <p>
                  {" "}
                  Optimize your logistics operations independently, saving both
                  time and money.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="box22">
            <div className="card" style={{ marginTop: "-5px" }}>
              <img src={shipments} alt="" />
              <div className="for-feat-text">
                <span>Fast Shipments</span>
                <p>
                  Lorem ipsum dolor sit amet,to the consectetur adipiscing elit.
                  Lacus, faucibus amet{" "}
                </p>
              </div>
            </div>
            <div className="card" style={{ marginTop: "25px" }}>
              <img src={delivery} alt="" />
              <div className="for-feat-text">
                <span>On-Time Delivery</span>
                <p>
                  Lorem ipsum dolor sit amet,to the consectetur adipiscing elit.
                  Lacus, faucibus amet{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="box33">
            <div className="card-last">
              <img src={savetime} alt="" />
              <div className="for-feat-text">
                <span>Save Time & Money </span>
                <p>
                  Optimize your logistics operations independently, saving both
                  time and money.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Logistic;

const LogisticsBenefits = () => {
  const content = [
    "Commission-Free Logistics: Enjoy a commission-free model that empowers users with cost - effective and transparent logistics solutions.",

    "Direct Connections: Facilitate seamless collaborations for transporting goods, ensuring a direct and reliable logistics experience.",

    "Shared Vehicle Option: Opt for shared vehicle logistics, allowing users to sharetransportation space for more sustainable and economical shipping.",

    "Flexible Negotiations: Benefit from a user-centric approach that allows for personalized negotiations, ensuring a fair and tailored logistics experience.",

    "Real-Time Shipment Tracking: Experience the convenience of real-timetracking for your shipments, providing visibility and control throughout the transportation process.",
    "Community Collaboration: Join a community-centric platform that fosters collaboration between users and logistics service providers.",
  ];

  return (
    <div className="shipping-container">
      <div className="shipping-content">
        {content.map((line, index) => {
          const [beforeColon, afterColon] = line.split(":");
          return (
            <p key={index}>
              <strong>{beforeColon}</strong>: {afterColon}
            </p>
          );
        })}
      </div>
    </div>
  );
};
