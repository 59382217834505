import "../styles/PoolRide.css";
import circley from "../Assist/Taxi-stand-img/Poll-Ride-main.png";
import Gplaystore from "../Assist/img/google.png";
import Iplaystore from "../Assist/img/iso.png";
import man_With_car from "../Assist/Taxi-stand-img/man-getting-car-medium-shot 1.png";
import man_With_car2 from "../Assist/Taxi-stand-img/travel-city 1.png";
import Line from "../Assist/Taxi-stand-img/Line 26.png";
import Incity from "../Assist/Taxi-stand-img/In & out of city.png";
import Ladies from "../Assist/Taxi-stand-img/Carpool.png";
import tracking from "../Assist/Taxi-stand-img/Map Marker.png";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function PoolRide() {
  return (
    <div>
      <div className="pool-ride-cover">
        <div className="pool-text">
          <h3>
            {" "}
            <span>Pool Ride</span>
          </h3>
          <div className="poll-ride-h4">
            {" "}
            <h4>Ride Sharing, Free of Extra Fees</h4>
          </div>
          <p>
            The Pool Ride service is here to redefine ride-sharing, offering a
            commission-free way to connect with others willing to share their
            trips. Enjoy the benefit of shared rides without added costs. This
            feature encourages collaborative and eco-friendly travel while
            leaving the fare negotiations in your hands, allowing for easy
            decision-making that suits your budget and preferences.
          </p>
          <div className="playstore-img">
            <div className="playstore-img-inner">
              <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                {" "}
                <img src={Gplaystore} alt="Google-PlayStore" />
                <br />
              </Link>
            </div>
            <div className="playstore-img-inner">
              <Link to="https://www.apple.com/app-store/">
                <img src={Iplaystore} alt="App-Store" />
              </Link>
            </div>
          </div>
        </div>

        <div className="pool-img-inner">
          <img src={circley} alt="" />
        </div>
      </div>

      <div className="Benefits-Drivers-main">
        <div className="Benefits-top-text">
          <h1>Benefits Of Pool Ride For Users</h1>
        </div>

        <div className="Benefits-Drivers">
          <div className="mob-r-img" style={{ padding: "0px ,20px" }}>
            <div className="Benefits-Drivers-img1">
              <img src={man_With_car} alt="" />
            </div>
          </div>
          <div className="Benefits-Drivers-img">
            <img src={man_With_car} alt="" />
          </div>
          <div className="Drive-Travelpartner-text1">
            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>Share rides with others, reducing travel expenses</p>
            </div>
            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>
                Fewer vehicles on the road contribute to a greener environment.
              </p>
            </div>
            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>Meet and interact with fellow travelers </p>
            </div>

            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>Share routes and save time during your commute.</p>
            </div>
          </div>
        </div>

        <div className="Benefits-Drivers">
          <div
            className="Drive-Travelpartner-text1"
            style={{ marginBottom: "20px" }}
          >
            <div className="Benefits-Drivers-img1">
              <img src={man_With_car2} alt="" />
            </div>
            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>
                Option to choose between male or female-only rides for added
                comfort and security.
              </p>
            </div>
            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>Fewer cars mean less congestion and smoother journeys</p>
            </div>
            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>save money and meet people</p>
            </div>

            <div className="for-arrow1">
              <div className="line-img1">
                <img src={Line} alt="" />
              </div>
              <p>Enjoy flexible scheduling and route choices</p>
            </div>
          </div>
          <div className="Benefits-Drivers-img">
            <img src={man_With_car2} alt="" />
          </div>
        </div>
      </div>
      <div className="Featured-Categories">
        <div className="main-featured-text">
          <h1>Best Things Of Travel partner</h1>
        </div>

        <div className="box-main">
          <div className="box11">
            <div className="card">
              <img src={Incity} alt="" />
              <div className="for-feat-text">
                <span>In & out of city</span>
                <p>
                  {" "}
                  Enjoy the convenience of Traveling Partner's pool ride service
                  for both city and inter-city travel. Connect with users
                  traveling in and out of the city for seamless and
                  cost-effective rides.
                </p>
              </div>
            </div>
          </div>
          <div className="box22">
            <div className="card">
              <img src={Ladies} alt="" />
              <div className="for-feat-text">
                <span>Female-Only</span>
                <p>
                  {" "}
                  Choose our dedicated female-only option for secure and
                  comfortable rides, with the ability to select female drivers
                  or pool with other female passengers.
                </p>
              </div>
            </div>
          </div>
          <div className="box33">
            <div className="card">
              <img src={tracking} alt="" />
              <div className="for-feat-text">
                <span>Live tracking</span>
                <p>
                  Stay informed in real-time with live tracking features.
                  Whether you're in the city or on an inter-city journey,
                  experience hassle-free tracking without additional charges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PoolRide;
